<template>
  <div class="amenity--field" :class="{ 'has-error': validation.hasError('amenity') }">
    <span class="left--text">{{ index + 1 }}. </span>
    <input
      name="amenities[]"
      :value="amenity"
      @input="inputAmenity"
      :placeholder="$t('addListing.placeholder.amenity')"
      class="basic--input"
      type="text"
    />
    <img
      v-show="amenityLength > 1"
      src="@/assets/img/icons/trash.svg"
      @click="removeAmenity"
      class="trash--button"
    />
    <span class="val-error" v-if="validation.hasError('amenity')">{{
      validation.firstError('amenity')
    }}</span>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

export default {
  name: 'amenity-field',
  props: ['amenity', 'index', 'amenityLength'],
  validators: {
    amenity: {
      validator(value) {
        return Validator.value(value).maxLength(
          100,
          this.$i18n.t('errors.addListing.amenities.max', { maxChar: 100 }),
        );
      },
    },
  },
  methods: {
    inputAmenity($event) {
      this.$store.commit('v2/listingForm/sect1/location/INPUT_AMENITY', {
        input: $event.target.value,
        index: this.index,
      });
    },
    removeAmenity(index) {
      this.$store.commit('v2/listingForm/sect1/location/REMOVE_AMENITY', index);
    },
    propOwnerChange($elem) {
      const payload = {
        propertyOwnerTypeId: this.propertyOwnershipTypeId,
        propertyOwnerTypeName: $elem.target.value,
      };
      this.$store.commit('v2/listingForm/sect1/location/CHANGE_PROP_OWNERSHIP_NAME', payload);
    },
  },
};
</script>

<style scoped></style>
